@use '../../scss/abstract/variables';

.empty-list {
    &--card-inventory {
        height: 72vh;

        &--card-inventory::before {
            content: '';
            position: absolute;
            top: 155px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background: variables.$white;
        }
    }

    &--text {
        display: flex;
        padding: 24px 30px 30px 30px;
        flex-direction: column;
        align-items: center;
        margin: 40px;
        text-align: center;
        border-radius: 16px;
        background: #fff;
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
    }

    &--icon {
        display: flex;
        width: 120px;
        height: 120px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin: auto;
    }
}

.client-empty-list {
    height: 40vh;
    margin: 30% 0;
}